<template>
  <div>
    <!-- 卡片视图区域 -->
    <el-card>
      <!-- 第一行，输入框和添加按钮 -->
      <el-row>
        <!-- 添加按钮 -->
        <el-col :span="5">
          <el-button type="primary" @click="showAddFoodTestDialog"
            >+ 新增食安设备</el-button
          >
        </el-col>
      </el-row>
      <!-- 内容主体 -->
      <el-table :data="foodTestList" stripe border>
        <!-- 第一列id -->
        <el-table-column label="设备编号" aligin="center" prop="deviceId">
        </el-table-column>
        <!-- 第二列名称 -->
        <el-table-column label="备注" aligin="center" prop="remark">
        </el-table-column>
        <!-- 第七列操作 -->
        <el-table-column label="操作" aligin="center">
          <template slot-scope="scope">
            <!-- 删除按钮 -->
            <el-button
              size="mini"
              type="danger"
              @click="removeFoodTestById(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNo"
        :page-sizes="[15, 30, 50, 100]"
        :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 添加对话框 -->
    <el-dialog
      @close="clearDialog"
      :title="title"
      :visible.sync="addFoodTestDialogVisable"
      width="30%"
    >
      <el-form
        ref="addFoodTestRef"
        :rules="addFoodTestRules"
        :model="foodTestUserForm"
        label-width="80px"
      >
        <el-form-item label="设备编号" prop="deviceId">
          <el-input v-model="foodTestUserForm.deviceId" clearable></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="foodTestUserForm.remark" clearable></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addFoodTestDialogVisable = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 查询食安设备入参
      queryInfo: {
        limit: 15,
        pageNo: 1,
        marketId: ''
      },
      // 食安设备列表
      foodTestList: [],
      // 总数
      total: 0,
      // 食安设备添加对话框
      addFoodTestDialogVisable: false,
      title: '',
      submitType: '',
      // 新增入参
      foodTestUserForm: {
        deviceId: '',
        marketId: undefined,
        remark: ''
      },
      // 添加对话框规则
      addFoodTestRules: {
        deviceId: [
          { required: true, message: '请填写设备编号', trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    // 获取食安设备列表
    this.getFoodTestList()
  },
  methods: {
    // 定义食安设备列表请求数据
    async getFoodTestList() {
      // 取marketId
      this.queryInfo.marketId = window.sessionStorage.getItem('currentMarketId')
      const { data: res } = await this.$http.get(
        'food-test/qualityTestDevice/list',
        {
          params: this.queryInfo
        }
      )
      if (res.code !== 0) {
        return this.$message.error('查询食安设备失败')
      }
      this.foodTestList = res.data.data
      this.total = res.data.total
    },
    // 打开增加食安设备对话框
    showAddFoodTestDialog() {
      // 调用市场列表数据请求
      this.title = '添加食安设备'
      this.submitType = 'add'
      this.foodTestUserForm.marketId = parseInt(
        window.sessionStorage.getItem('currentMarketId')
      )

      this.addFoodTestDialogVisable = true
    },
    // limit变化，调用列表接口
    handleSizeChange(newSize) {
      this.queryInfo.limit = newSize
      this.getFoodTestList()
    },
    // 页码变化，调用接口
    handleCurrentChange(newPage) {
      this.queryInfo.pageNo = newPage
      this.getFoodTestList()
    },
    // 删除食安设备
    async removeFoodTestById(id) {
      console.log(id)
      // 弹框提醒
      const confirmResult = await this.$confirm(
        '此操作将永久删除该食安设备，是否继续？',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除')
      }
      const { data: res } = await this.$http.delete(
        `food-test/qualityTestDevice/${id}`
      )
      if (res.code !== 0) {
        return this.$message.error('删除食安设备失败')
      }
      this.$message.success('删除食安设备成功')
      this.getFoodTestList()
    },
    // 关闭对话框，清空参数
    clearDialog() {
      this.foodTestUserForm = {}
      this.$refs.addFoodTestRef.resetFields()
      this.getFoodTestList()
    },
    // 提交新增和修改请求
    async submit() {
      this.$refs.addFoodTestRef.validate(async valid => {
        // console.log(valid)
        if (!valid) return
        // 可以发起网络请求
        if (this.submitType === 'add') {
          const { data: res } = await this.$http.post(
            'food-test/qualityTestDevice/',
            this.foodTestUserForm
          )
          if (res.code !== 0) {
            return this.$message.error('添加食安设备失败')
          }
          this.$message.success('添加食安设备成功')
          this.getFoodTestList()
          this.addFoodTestDialogVisable = false
        } else {
        }
      })
    },
    select() {
      this.$forceUpdate()
    }
  }
}
</script>

<style lang="less" scoped>
.el-col {
  margin-right: 20px;
}
.el-tag {
  margin: 7px;
}
.el-row {
  margin-bottom: 20px;
}
</style>
